<template>
    <h1>Good Events Co.</h1>
    <div class="home">
        <EventCard v-for="event in events" :key="event.id" :event="event" />
    </div>
</template>

<script>
// @ is an alias to /src
import EventCard from '@/components/EventCard.vue'
import EventService from '@/services/EventService.js'

export default {
    name: 'EventList',
    components: {
        EventCard,
    },
    data() {
        return {
            events: null,
        }
    },
    created() {
        EventService.getEvents()
            .then((resp) => (this.events = resp.data))
            .catch((err) => console.error(err))
    },
}
</script>

<style scoped>
.home {
    align-items: center;
    display: flex;
    flex-direction: column;
}
</style>
